import {isJSONObject} from '@backstage-components/base';
import type {Attendee, Context} from './attendee-container-machine';
import {
  VerifyPublicPasscode,
  VerifyPublicPasscodeMutation,
  VerifyPublicPasscodeMutationVariables,
} from './gql';

/**
 * Verify that an Attendee with the context and access code provided exists in a
 * particular show. Thrown errors will be caught by the state machine.
 * @returns details about the attendee for the verified access code
 * @throws when errors returned by GraphQL
 * @throws when no data from the `verifyAccessCode` mutation
 */
export async function verifyPublicAttendee({
  context,
  showId,
  siteVersionId,
  passCode,
  name,
  moduleId,
}: VerifyPublicAttendeProps): Promise<Attendee> {
  return context.client
    .mutate<VerifyPublicPasscode, VerifyPublicPasscodeMutationVariables>({
      mutation: VerifyPublicPasscodeMutation,
      variables: {data: {showId, siteVersionId, passCode, name, moduleId}},
      context: {showId: context.showId},
    })
    .then((result) => {
      const {errors, data} = result;

      // if an error message was returned, throw it
      if (typeof errors !== 'undefined') {
        throw new Error(errors.map((e) => e.message).join('  '));
      }

      if (data?.verifyPublicPasscode.__typename === 'DataIntegrityError') {
        const {code, message} = data.verifyPublicPasscode;
        throw new Error(`${code} -- ${message}`);
      } else if (
        data?.verifyPublicPasscode.__typename === 'PublicPasscodeOutput'
      ) {
        // return the public attendee's data
        const {id, name, email, sessionToken, chatTokens, attendeeTags} =
          data.verifyPublicPasscode;

        const chatToken = chatTokens[0];
        if (
          typeof chatToken !== 'undefined' &&
          isJSONObject(chatToken) &&
          'token' in chatToken &&
          typeof chatToken.token === 'string'
        ) {
          const {token} = chatToken;
          const result: Attendee = {
            id,
            name,
            email,
            sessionToken,
            chatTokens: [{token}],
            attendeeTags,
            attendeeType: 'pass-code',
          };
          return result;
        }
      }
      throw new Error('Malformed chat token.');
    })
    .catch((reason) => {
      if (
        reason instanceof Error &&
        reason.message.includes('InvalidPublicPasscode')
      ) {
        throw new InvalidPublicPasscodeError(reason.message);
      }
      throw reason;
    });
}

type VerifyPublicAttendeProps = {
  /** Details provided by the browser client */
  context: Context;
  /** The id of the show to verify */
  showId: string;
  /** The published version of the site to verify against */
  siteVersionId?: string;
  /** The public access code to verify */
  passCode: string;
  /** The name the attendee wishes to use in chat */
  name: string;
  /** The identifier of the module that is requesting the verification */
  moduleId: string;
};

/**
 * Custom `Error` implementation to distinguish an invalid public passcode.
 */
export class InvalidPublicPasscodeError extends Error {
  constructor(m: string) {
    super(m);
    // Set the prototype explicitly
    // See https://github.com/Microsoft/TypeScript/wiki/Breaking-Changes#generated-constructor-code-substitutes-the-return-value-of-super-calls-as-this
    Object.setPrototypeOf(this, InvalidPublicPasscodeError.prototype);
  }
}
